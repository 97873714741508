import React from 'react'
import OpenVista from "../components/Product1/Product1"
import Navbar from "../components/Navbar/Navbar";

function Product1() {
  return (
    <div>
        <Navbar />
        <OpenVista />
    </div>
  )
}

export default Product1