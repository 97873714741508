import React from 'react'
import TailorCraft from "../components/Product3/Product3"
import Navbar from '../components/Navbar/Navbar'

function Product3() {
  return (
    <div>
        <TailorCraft />
        <Navbar />
    </div>
  )
}

export default Product3