import React from 'react'
import Contact from "../components/ContactUs/Contact/Contact"
import Navbar1 from "../components/Navbar1/Navbar1";
function ContactUs() {
  return (
    <div>
        <Navbar1 />
        <Contact />
    </div>
  )
}

export default ContactUs