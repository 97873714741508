import React from 'react'
import Armor from "../components/Product2/Product2"
import Navbar from "../components/Navbar/Navbar";

function Product2() {
  return (
    <div>
        <Navbar />
        <Armor />
    </div>
  )
}

export default Product2